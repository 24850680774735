import Image from 'next/image'
import styles from '../../components/style/home/MarketPlaceTopProducts.module.css'
import { NavLink } from '../ui'
import { pushRoute } from '@/lib/helpers/common';
import { ITopCategory } from '@/interfaces/IMarketPlace';
type props = {
    marketplaceTopCategories: ITopCategory[]
}
export function MarketPlaceTopProducts({ marketplaceTopCategories }: props) {
    const baseUrl = `${process.env.SITE_URL}/online-store`;
    return (
        <section className="section">
            <div className={styles.discovrYdreminbox}>
                <div className="containerFull">
                    <div className="divScovrhedbox mb46 textCenter mbSM15">
                        <h3 className="text222 font26 fbold fontSM16 lineheightSM3  lineheightNom">
                            Discover Your Dream Home: Dive into Our Decor & Furniture Collection!
                        </h3>
                    </div>
                    <div className={styles.discoverImgminbox}>
                        {
                            marketplaceTopCategories && marketplaceTopCategories.length > 0 && marketplaceTopCategories.map((item: ITopCategory, index: number) => {
                                return (
                                    <div className={styles.discverImgtiles} key={index} onClick={() => pushRoute(`${baseUrl}/${item.serviceSlug}/${item.categorySlug}`)}>
                                        <div className={styles.diTileimgbox}>
                                            <Image width={200} height={200} className="img-responsive" src={item.imagePath} alt={item.categoryName} />
                                        </div>
                                        <div className={styles.discoImboxtext}>
                                            <div className={styles.disTexlftbox}>
                                                <NavLink href={`${baseUrl}/${item.serviceSlug}/${item.categorySlug}`}>
                                                    <span>{item.categoryName}</span>
                                                </NavLink>
                                                <p>Upto {item.maxDiscount}% Discount</p>
                                            </div>
                                            <div className={styles.disTxtrigbox}>
                                                <Image width={12} height={12} className="img-responsive" src="/assets/images/blackArrow.svg" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <div className={styles.discverImgtiles} onClick={() => pushRoute(`${baseUrl}`)}>
                            <div className={styles.diTileimgbox}>
                                <Image width={200} height={200} className="img-responsive" src="/assets/images/specialoffer.png" alt="" />
                            </div>
                            <div className={styles.discoImboxtext}>
                                <div className={styles.disTexlftbox}>
                                    <span>Explore More Deals</span>
                                    <NavLink href={`${baseUrl}`}>
                                        <p>Shop Now</p>
                                    </NavLink>
                                </div>
                                <div className={styles.disTxtrigbox}>
                                    <Image width={12} height={12} className="img-responsive" src="/assets/images/blackArrow.svg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}